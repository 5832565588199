import payload_plugin_qLmFnukI99 from "/home/runner/work/getgrain/getgrain/packages/app/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/home/runner/work/getgrain/getgrain/packages/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/getgrain/getgrain/packages/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/getgrain/getgrain/packages/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/getgrain/getgrain/packages/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/runner/work/getgrain/getgrain/packages/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/getgrain/getgrain/packages/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/getgrain/getgrain/packages/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/runner/work/getgrain/getgrain/packages/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/getgrain/getgrain/packages/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/getgrain/getgrain/packages/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_jg3TSEXw60 from "/home/runner/work/getgrain/getgrain/packages/app/node_modules/@hebilicious/vue-query-nuxt/dist/runtime/plugin.mjs";
import session_client_NxDDSatxox from "/home/runner/work/getgrain/getgrain/packages/app/node_modules/nuxt-auth-utils/dist/runtime/app/plugins/session.client.js";
import slideovers_X7whXyrIWR from "/home/runner/work/getgrain/getgrain/packages/app/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/home/runner/work/getgrain/getgrain/packages/app/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/home/runner/work/getgrain/getgrain/packages/app/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_OVoKJro5pc from "/home/runner/work/getgrain/getgrain/packages/app/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/home/runner/work/getgrain/getgrain/packages/app/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_Eg4DPJPcY6 from "/home/runner/work/getgrain/getgrain/packages/app/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_1aypKNZ222 from "/home/runner/work/getgrain/getgrain/packages/app/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_kQtglGecod from "/home/runner/work/getgrain/getgrain/packages/app/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import files_AW9BghCKjb from "/home/runner/work/getgrain/getgrain/packages/app/app/plugins/files.ts";
import sentry_GIZHzhsXXD from "/home/runner/work/getgrain/getgrain/packages/app/app/plugins/sentry.js";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_jg3TSEXw60,
  session_client_NxDDSatxox,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_client_OVoKJro5pc,
  plugin_WLsn00x1qh,
  scrollbars_client_Eg4DPJPcY6,
  presets_1aypKNZ222,
  variables_kQtglGecod,
  files_AW9BghCKjb,
  sentry_GIZHzhsXXD
]