import { defineStore } from "pinia";
import { useRuntimeConfig } from "nuxt/app";
export const useWebSocket = defineStore("webSocket", () => {
  const { websocket_url, api_url } = useRuntimeConfig().public;
  const webSocket = shallowRef();
  const messages = ref([]);
  async function fetchWebSocketToken() {
    const response = await fetch(`${api_url}/auth/websocket`, {
      method: "GET",
      credentials: "include", // Include session cookies
    });
    if (!response.ok) {
      throw new Error("Failed to fetch WebSocket token");
    }
    const data = await response.json();
    return data.token;
  }

  function sendMessage(message) {
    if (webSocket.value && webSocket.value.readyState === WebSocket.OPEN) {
      webSocket.value.send(JSON.stringify(message));
    } else {
      console.error("WebSocket is not open. Cannot send message.");
    }
  }

  async function initializeWebSocket() {
    try {
      const token = await fetchWebSocketToken();
      webSocket.value = new WebSocket(`${websocket_url}?token=${token}`);

      webSocket.value.onmessage = (event) => {
        const message = JSON.parse(event.data);
        messages.value.push(message); // Add the message to the messages array
      };

      webSocket.value.onopen = () => {
        // openWebSocket();
      };
      webSocket.value.onclose = () => {
        setTimeout(() => {
          initializeWebSocket();
        }, 1000);
      };
    } catch (error) {
      console.error("WebSocket initialization failed:", error);
      setTimeout(initializeWebSocket, 5000); // Retry after some time if token fetch fails
    }
  }

  return {
    initializeWebSocket,
    messages,
    sendMessage,
  };
});
